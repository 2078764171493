













  import { Component, Vue } from 'nuxt-property-decorator'
  import { Stocks } from '@one/types'
  import { namespace } from 'vuex-class'

  const stocks = namespace('stocks')

  @Component({
    components: {
      OneTopWarehouses: () => import('~/components/molecules/navigations/OneTopWarehouses.vue'),
    },
  })
  export default class WarehouseSelectorComponent extends Vue {
    @stocks.Getter hasWarehouses!: boolean
    @stocks.Getter getDefaultWarehouseId: any
    @stocks.Getter getWarehouse: any
    @stocks.State(state => state.warehouses.byId) warehousesMap: any
    @stocks.Mutation('SET_DEFAULT_WAREHOUSE') setDefaultWarehouse: any

    get warehouses(): Array<Stocks.Warehouse> {
      return Object.keys(this.warehousesMap).map(k => this.warehousesMap[k])
    }

    get currentWarehouse(): Stocks.Warehouse | null {
      return this.getWarehouse(this.getDefaultWarehouseId)
    }

    async onWarehouseSelected(warehouseId: string) {
      await this.$orderpath.cart.setWarehouseContext(warehouseId)
      this.setDefaultWarehouse(warehouseId)
    }
  }
